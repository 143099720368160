import React from 'react';
import { useRouter } from 'bb/app/router';
import { useStudent } from 'bb/campaigns/student/components/StudentContext';
import { Block } from 'bb/common/components';
import { RedeemOrCampaignButton } from 'bb/common/components/buttons';
import { ILLUSTRATIONS } from 'bb/discovery/components/HowItWorks/config';
import css from 'bb/discovery/components/HowItWorks/howItWorks.module.scss';
import { HowItWorksCard } from 'bb/discovery/components/HowItWorks/HowItWorksCard';
import { AmountPerMonth, Text } from 'bb/i18n';
import { Flex } from 'bb/ui';
import { getCampaignCodeFromQuery } from 'bb/utils';
import { HeaderSection } from '../HeaderSection';
import { type HowItWorksStep } from './HowItWorks';

export const SECTION_ID = 'studentHowItWorks';

export const StudentHowItWorks = () => {
    const { routes, router } = useRouter();
    const { trialDays, studentPrice, campaign } = useStudent();

    const steps: Omit<HowItWorksStep, 'id'>[] = [
        {
            img: ILLUSTRATIONS.createAccount,
            bgColor: 'primary-purple',
            header: 'studentV2:howItWorks1Header',
            text: <Text t="studentV2:howItWorks1Text" />
        },
        {
            img: ILLUSTRATIONS.student,
            bgColor: 'secondary-yellow',
            header: 'studentV2:howItWorks2Header',
            headerValues: { code: campaign.code },
            text: (
                <Text
                    t="studentV2:howItWorks2Text"
                    values={{ code: campaign.code }}
                />
            )
        },
        {
            img: ILLUSTRATIONS.greenDownloadApp,
            bgColor: 'secondary-green',
            header: 'studentV2:howItWorks3Header',
            text: (
                <Text t="studentV2:howItWorks3Text">
                    <AmountPerMonth
                        key="amountPerMonth"
                        amount={studentPrice}
                    />
                </Text>
            )
        }
    ];

    const campaignCode = getCampaignCodeFromQuery(router.query);

    return (
        <div id={SECTION_ID}>
            <HeaderSection
                title="studentV2:getStarted"
                heading="studentV2:getStartedHeader"
                subHeading="studentV2:getStartedText"
            >
                <Flex className={css.flex} alignItems="center">
                    {steps.map((step, index) => {
                        const stepNum = index + 1;

                        return (
                            <HowItWorksCard
                                key={stepNum}
                                step={stepNum}
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...step}
                            />
                        );
                    })}
                </Flex>

                <Block withPagePadding>
                    <Flex justifyContent="center">
                        <RedeemOrCampaignButton
                            name="studentpage-middle"
                            route={routes.userDetails}
                            className={css.wideButton}
                            fluid
                            marginTop="large"
                            campaignCode={campaignCode}
                            redeemCode={campaign.code}
                            addCampaignCodeToQuery={Boolean(campaignCode)}
                        >
                            <Text
                                t="studentV2:howItWorksCta"
                                values={{ trialDays }}
                            />
                        </RedeemOrCampaignButton>
                    </Flex>
                </Block>
            </HeaderSection>
        </div>
    );
};
