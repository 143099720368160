import React, { type FunctionComponent } from 'react';
import { useRouter } from 'bb/app/router';
import { type Campaign } from 'bb/campaigns/types';
import { DeprecatedLinkButton } from 'bb/common';
import { SignupButton } from 'bb/common/components/buttons';
import { type LinkButtonProps } from 'bb/common/DeprecatedLinkButton/LinkButton';
import { Text, useTranslation } from 'bb/i18n';
import { Flex, DeprecatedButton } from 'bb/ui';
import { HeaderSection } from '../HeaderSection';
import { ILLUSTRATIONS } from './config';
import css from './howItWorks.module.scss';
import { HowItWorksCard, type HowItWorksCardProps } from './HowItWorksCard';

interface HowItWorksProps {
    forGiftcard?: boolean;
    campaign?: Campaign;
}

export type HowItWorksStep = Omit<HowItWorksCardProps, 'step'> & { id: number };

export const HowItWorks: FunctionComponent<HowItWorksProps> = ({
    forGiftcard = false,
    campaign
}) => {
    const { t } = useTranslation(['start', 'common']);
    const { routes } = useRouter();
    const campaignQuery = campaign?.code && {
        campaign: campaign.code
    };

    const steps: HowItWorksStep[] = [
        {
            id: 1,
            img: ILLUSTRATIONS.createAccount,
            bgColor: 'primary-purple',
            header: 'start:createAccountHeader',
            text: (
                <Text
                    t="start:createAccountText"
                    data-optimizely="howItWorks-createAccountText"
                >
                    <DeprecatedLinkButton
                        inline
                        variant="underline"
                        route={routes.userDetails}
                        query={
                            campaignQuery as LinkButtonProps<'underline'>['query']
                        }
                    />
                </Text>
            )
        },
        {
            id: 2,
            img: ILLUSTRATIONS.downloadApp,
            bgColor: 'secondary-yellow',
            header: 'start:downloadAppHeader',
            text: (
                <Text
                    t="start:downloadAppText"
                    data-optimizely="howItWorks-downloadAppText"
                >
                    <DeprecatedButton
                        as="a"
                        inline
                        variant="underline"
                        href={t('common:appStoreLink') ?? ''}
                    />
                    <DeprecatedButton
                        as="a"
                        inline
                        variant="underline"
                        href={t('common:playStoreLink') ?? ''}
                    />
                </Text>
            )
        },
        {
            id: 3,
            img: ILLUSTRATIONS.ears,
            bgColor: 'secondary-red',
            header: 'start:offertHeader',
            text: (
                <Text
                    t="start:offertText"
                    data-optimizely="howItWorks-offertText"
                />
            )
        }
    ];

    return (
        <HeaderSection
            title="start:getStarted"
            heading="start:getStartedHeader"
            subHeading="start:getStartedText"
            cta={
                <SignupButton
                    query={campaignQuery as LinkButtonProps<'primary'>['query']}
                    name="startpage-howitworks"
                >
                    <Text as="span" t="start:getStartedCta" />
                </SignupButton>
            }
            className={forGiftcard ? css.dark : css.light}
            data-optimizely="howItWorks-section"
        >
            <Flex className={css.flex} alignItems="center">
                {steps.map(({ img, bgColor, header, text, id }, index) => (
                    <HowItWorksCard
                        key={id}
                        step={index + 1}
                        img={img}
                        bgColor={bgColor}
                        header={header}
                        text={text}
                    />
                ))}
            </Flex>
        </HeaderSection>
    );
};
